<template>
  <section
    id="faq-search-filter"
    class="banner"
  >
    <b-row class="mb-4">
      <b-col
        cols="3"
        class="box-img"
      >
        <b-img
          fluid
          src="@/assets/images/elements/voucher-validate01.png"
          alt="validate voucher"
        />
      </b-col>
      <b-col cols="6">
        <div
          no-body
          :name="title"
          class="pt-4 pb-4"
        >
          <div
            :name="{title}"
            class="text-center "
          >
            <h2>
              {{ title }}
            </h2>
            <template
              :name="subtitle"
            >
              <span v-html="subtitle" />
            </template>
          </div>
        </div>
      </b-col>
      <b-col
        cols="3"
        class="box-img"
      >
        <b-img
          fluid
          src="@/assets/images/elements/voucher-validate02.png"
          alt="validate voucher"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
// import { BCard } from 'bootstrap-vue'

import {
  BRow,
  BCol,
  BImg,

} from 'bootstrap-vue'

export default {
  name: 'BannerHeader',
  components: {
    // BCard,
    BRow,
    BCol,
    BImg,
  },
  props: {
    title: String,
    subtitle: String,
  },
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/pages/page-faq.scss';
    .banner {
      background: linear-gradient(180deg, rgba(82, 154, 255, 0.8) 0%, rgba(126, 139, 255, 0.8) 48.96%, rgba(160, 104, 255, 0.8) 100%);
      border-radius: 0.5rem;
      padding: 0 6rem;

      h2, p, span {
        color: #FFF
      }

      .box-img {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
</style>
