<template>
  <section>

    <b-row class="mb-2">
      <b-col
        cols="12"
      >
        <!-- <b-img
          name="banner-voucher"
          :src="require('@/assets/images/illustration/validate-voucher.svg')"
          alt="basic svg img"
        /> -->
        <banner-header
          name="validar"
          title="Validar Cupom"
          subtitle="<p>Esta sessão foi pensada para facilitar o processo de baixa de cupom.</p> <p>Aqui você pode validar tanto um cupom de indicação, quanto um voucher de recompensa.</p>"
        />
      </b-col>
      <b-col
        cols="8"
        offset-md="2"
        class="pr-5 pl-5 mb-2"
        style="text-align: center"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <div class="box-voucher">
            <h4 class="mb-2">
              Informe aqui o cupom a ser validado
            </h4>

            <validation-observer ref="voucherValidation">
              <b-form
                class="px-5 form-validate"
              >
                <b-form-group
                  label="Informe o cupom a ser validado"
                  label-for="voucherCode"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cupom"
                    rules="required"
                  >
                    <b-form-input
                      v-model="voucherCode"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      placeholder="Código do cupom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Validar cupom
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="8"
        offset-md="2"
        class="pr-5 pl-5"
      >
        <p>
          Clicando em validar cupom pela primeira vez, <b>caso seja um cupom de indicação</b>, ocorrerá uma <b>atualização automática de status</b> de CUPOM GERADO para CUPOM ATIVADO, e você terá 30 dias para finalizar o processo de baixa ou cancelamento.
        </p>

      </b-col>
    </b-row>

    <!--modal erro-->
    <b-modal
      v-model="showModalErro"
      ok-title="Voltar"
      centered
      ok-only
    >
      <div class="modal-erros">
        <feather-icon
          icon="AlertTriangleIcon"
          size="60"
          class="text-danger"
        />
        <h2>Erro Inesperado</h2>
        <p>
          Lamentamos o ocorrido. Tente novamente.
        </p>
      </div>
    </b-modal>

    <!--modal erro-->
    <b-modal
      v-model="showModalErroVoucherExpired"
      ok-title="Voltar"
      centered
      ok-only
    >
      <div class="modal-erros">
        <feather-icon
          icon="AlertCircleIcon"
          size="60"
          class="text-warning"
        />
        <h2>Cupom expirado</h2>
        <p>
          Cupom já se encontra em status finalizado
        </p>
      </div>
    </b-modal>

    <!--modal erro-->
    <b-modal
      v-model="showModalErroCustomerBenefited"
      ok-title="Voltar"
      centered
      ok-only
    >
      <div class="modal-erros">
        <feather-icon
          icon="AlertOctagonIcon"
          size="60"
          class="text-warning"
        />
        <h2>Este benefício já foi
          concedido ao cliente :( </h2>
        <p>
          O indicado já utilizou o benefício com outro indicador,
          e não há como resgatar o benefício mais de uma vez
        </p>
      </div>
    </b-modal>

    <!--modal erro-->
    <b-modal
      v-model="showModalErrorVoucherCancelate"
      ok-title="Voltar"
      centered
      ok-only
    >
      <div class="modal-erros">
        <feather-icon
          icon="AlertOctagonIcon"
          size="60"
          class="text-warning"
        />
        <h2>Cupom Cancelado.</h2>
        <p>
          Este cupom foi cancelado pela empresa.
        </p>
      </div>
    </b-modal>

    <!--modal erro-->
    <b-modal
      v-model="showModalErrorVoucherNegative"
      ok-title="Voltar"
      centered
      ok-only
    >
      <div class="modal-erros">
        <feather-icon
          icon="AlertOctagonIcon"
          size="60"
          class="text-warning"
        />
        <h2>Cupom Negado.</h2>
        <p>
          Recompensa não elegível para colaboradores.
        </p>
      </div>
    </b-modal>

  </section>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BModal, BOverlay,
} from 'bootstrap-vue'

// import { api } from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import { mapGetters } from 'vuex'

import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

import BannerHeader from '../components/BannerHeader.vue'

export default {
  components: {
    BRow,
    BCol,
    // BImg,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BModal,
    BannerHeader,
    BOverlay,
  },
  data() {
    return {
      required,
      voucherCode: '',
      showModalErro: false,
      showModalErroVoucherExpired: false,
      showModalErroCustomerBenefited: false,
      showModalErrorVoucherCancelate: false,
      showModalErrorVoucherNegative: false,
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  mounted() {
    localize('pt_BR')
  },
  methods: {
    validationForm() {
      this.$refs.voucherValidation.validate().then(success => {
        if (success) {
          this.formSubmitted()
        }
      })
    },
    formSubmitted() {
      // api.get(`/coupon/validate/${this.voucherCode}`)
      useJwt.validateCoupon(this.voucherCode)
        .then(response => {
          const infoVoucher = {
            couponToken: response.data.result.couponToken,
            couponType: response.data.result.couponType,
            voucherDescription: response.data.result.campaign.benefitIndicated.benefit,
            expirationDate: response.data.result.expirationDate ? toConvertDateTimezoneToConventionalDate(response.data.result.expirationDate) : '',

            indicatedName: response.data.result.campaign.benefitIndicated.name,
            indicatedEmail: response.data.result.campaign.benefitIndicated.email,
            indicatedPhone: response.data.result.campaign.benefitIndicated.phoneNumber,

            indicatorName: response.data.result.campaign.benefitIndicator.name,
            indicatorReward: response.data.result.campaign.benefitIndicator.type === 'voucher'
              ? response.data.result.campaign.benefitIndicator.description : response.data.result.campaign.benefitIndicator.amount,
            indicatorRewardMoneyFormat: response.data.result.campaign.benefitIndicator.format,
            currentStatus: response.data.result.currentStatus.status,
            currentStage: response.data.result.currentStatus.stage,
            currentStageDescription: response.data.result.currentStatus.description,
          }

          const queryParam = btoa(JSON.stringify(infoVoucher))

          this.$router.push({
            name: 'confirm-data',
            query: { infoVoucher: queryParam },
          })
        })
        .catch(error => {
          if (error.response.data.errors[0] === 'Indicação confirmada.') {
            this.showModalErroCustomerBenefited = true
          } else if (error.response.data.errors[0] === 'Cupom expirado.') {
            this.showModalErroVoucherExpired = true
          } else if (error.response.data.errors[0] === 'Indicação cancelada.') {
            this.showModalErrorVoucherCancelate = true
          } else if (error.response.data.errors[0] === 'Recompensa não elegível para colaboradores.') {
            this.showModalErrorVoucherNegative = true
          } else if (error.response.data.errors[0] === 'Necessário registrar cupom.') {
            const infoVoucher = {
              indicatorCampaignToken: error.response.data.result.indicatorCampaignToken,
              couponType: error.response.data.result.couponType,
              voucherDescription: error.response.data.result.campaign.benefitIndicated.benefit,
              expirationDate: error.response.data.result.expirationDate ? toConvertDateTimezoneToConventionalDate(error.response.data.result.expirationDate) : null,

              indicatorName: error.response.data.result.campaign.benefitIndicator.name,
              indicatorReward: error.response.data.result.campaign.benefitIndicator.type === 'voucher'
                ? error.response.data.result.campaign.benefitIndicator.description : error.response.data.result.campaign.benefitIndicator.amount,
              indicatorRewardMoneyFormat: error.response.data.result.campaign.benefitIndicator.format,

            }

            const queryParam = btoa(JSON.stringify(infoVoucher))
            this.$router.push({
              name: 'generate-lead',
              query: { infoVoucher: queryParam },
            })
          } else {
            this.showModalErro = true
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>

// @import '@core/scss/base/components/_variables.scss';

    .form-validate {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15rem;

        .form-group {
            flex: 1;
            margin-right: 1rem;
        }
    }

    .modal-erros{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h2{
        margin: 1rem 0;
        font-size: 1.4rem;
      }
    }
</style>
